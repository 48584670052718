// store.js
import { reactive, computed } from "vue";
// import { rp, tour } from "@/components/tour.vue";

export const sceneManager = reactive({
  selectedLVI: null,
  iframeWindow: null,
  activeCategory : "Viewpoints",
  WQMitigatedState: true,
  mapShow: true,
  currentScene: "",
  triggerOverlayName: "impact_noise_def",
  currentFocus: "none",
  iframeSrc: computed(() => {
    return `/tour/index.html?media-name=${sceneManager.currentScene}&hide-components-tags=landing_page&trigger-overlay-name=${sceneManager.triggerOverlayName}&focus-overlay-name=${sceneManager.triggerOverlayName}`;
  }),
  rp: null,
  RP() {
    this.rp = this.iframeWindow.tour._getRootPlayer();
    return this.rp;
  },
  setCurrentScene(name) {
    console.log("setting scene to " + name);
    name = name.replace("-", "");
    this.currentScene = name;
  },
  //manipulate tour in iframe in tour.vuw
  manipulateTour() {
    console.log(
      this.iframeWindow.tour._getRootPlayer().mainPlayList.get("items")
    );
  },

  // generateUrl() {
  //   let url = this.iframeSrc;
  //   return url;
  // },
  setScene(a) {
    console.log(a);
  },
  test() {
    console.log("test");
  },

  getVPName() {
    let VpArray = this.RP().mainPlayList.get("items");

    let VpTitleArray = [];

    for (let i = 0; i < VpArray.length; i++) {
      let VpItem = VpArray[i];

      let VpItemTitle = VpItem["media"]["label"];

      VpTitleArray.push(VpItemTitle);
    }
    console.log(VpTitleArray);

    return VpTitleArray;
  },
  //get the iframe in tour.vue
});
