export const categories = [
  {
    name: "Overview",
    fullName: "Viewpoints",
    icon: "/icons/overview.svg",
    list: [
      { name: "TKO 132", link: "X", clickable: false },
      { name: "VP B1", link: "VPB1", clickable: true },
      { name: "VP B2", link: "VPB2", clickable: true },
      { name: "VP B3", link: "VPB3", clickable: true },
      { name: "TKO 137", link: "X", clickable: false },
      { name: "VP A1", link: "VPA1", clickable: true },
      { name: "VP A2", link: "VPA2", clickable: true },
      { name: "VP A3", link: "VPA3", clickable: true },
      { name: "VP A4", link: "VPA4", clickable: true },
      { name: "VP A5", link: "VPA5", clickable: true },
      { name: "VP A6", link: "VPA6", clickable: true },
      { name: "VP A7", link: "VPA7", clickable: true },
    ],
  },
  {
    //>> noise list
    name: "Noise",
    fullName: "Noise",
    icon: "/icons/noise.svg",
    list: [
      {
        name: "TKO 132",
        link: "X",
        clickable: false,
        triggerOverlayName: "main_road_noise",
        hasData: null,
      },
      {
        name: "VP B1",
        link: "VPB1",
        clickable: true,
        triggerOverlayName: "main_road_noise",
        hasData: true,
      },
      {
        name: "VP B2",
        link: "VPB2",
        clickable: true,
        triggerOverlayName: "main_road_noise",
        hasData: true,
      },
      {
        name: "VP B3",
        link: "VPB3",
        clickable: true,
        triggerOverlayName: "main_road_noise",
        hasData: true,
      },
      {
        name: "TKO 137",
        link: "X",
        clickable: false,
        triggerOverlayName: "main_road_noise",
        hasData: null,
      },
      {
        name: "VP A1",
        link: "VPA1",
        clickable: true,
        triggerOverlayName: "main_road_noise",
        hasData: false,
      },
      {
        name: "VP A2",
        link: "VPA2",
        clickable: true,
        triggerOverlayName: "main_road_noise",
        hasData: true,
      },
      {
        name: "VP A3",
        link: "VPA3",
        clickable: true,
        triggerOverlayName: "main_road_noise",
        hasData: true,
      },
      {
        name: "VP A4",
        link: "VPA4",
        clickable: true,
        triggerOverlayName: "main_road_noise",
        hasData: true,
      },
      {
        name: "VP A5",
        link: "VPA5",
        clickable: true,
        triggerOverlayName: "main_road_noise",
        hasData: true,
      },
      {
        name: "VP A6",
        link: "VPA6",
        clickable: true,
        triggerOverlayName: "main_road_noise",
        hasData: true,
      },
      {
        name: "VP A7",
        link: "VPA7",
        clickable: true,
        triggerOverlayName: "main_road_noise",
        hasData: true,
      },
    ],
    //>> marine traffic list
    marine_list: [
      {
        name: "TKO 132",
        link: "X",
        clickable: false,
        triggerOverlayName: "main_marine_noise",
        hasData: null,
      },
      {
        name: "VP B1",
        link: "VPB1",
        clickable: true,
        triggerOverlayName: "main_marine_noise",
        hasData: true,
      },
      {
        name: "VP B2",
        link: "VPB2",
        clickable: true,
        triggerOverlayName: "main_marine_noise",
        hasData: true,
      },
      {
        name: "VP B3",
        link: "VPB3",
        clickable: true,
        triggerOverlayName: "main_marine_noise",
        hasData: true,
      },
      {
        name: "TKO 137",
        link: "X",
        clickable: false,
        triggerOverlayName: "main_marine_noise",
        hasData: null,
      },
      {
        name: "VP A1",
        link: "VPA1",
        clickable: true,
        triggerOverlayName: "main_marine_noise",
        hasData: true,
      },
      {
        name: "VP A2",
        link: "VPA2",
        clickable: true,
        triggerOverlayName: "main_marine_noise",
        hasData: false,
      },
      {
        name: "VP A3",
        link: "VPA3",
        clickable: true,
        triggerOverlayName: "main_marine_noise",
        hasData: false,
      },
      {
        name: "VP A4",
        link: "VPA4",
        clickable: true,
        triggerOverlayName: "main_marine_noise",
        hasData: false,
      },
      {
        name: "VP A5",
        link: "VPA5",
        clickable: true,
        triggerOverlayName: "main_marine_noise",
        hasData: false,
      },
      {
        name: "VP A6",
        link: "VPA6",
        clickable: true,
        triggerOverlayName: "main_marine_noise",
        hasData: false,
      },
      {
        name: "VP A7",
        link: "VPA7",
        clickable: true,
        triggerOverlayName: "main_marine_noise",
        hasData: false,
      },
    ],
  },
  {
    name: "Air",
    fullName: "Air",
    icon: "/icons/air.svg",
    list: [
      {
        name: "TKO 132",
        link: "X",
        clickable: false,
        triggerOverlayName: "",
        hasData: null,
      },
      {
        name: "VP B1",
        link: "VPB1",
        clickable: true,
        triggerOverlayName: "main_air",
        hasData: true,
      },
      {
        name: "VP B2",
        link: "VPB2",
        clickable: true,
        triggerOverlayName: "main_air",
        hasData: true,
      },
      {
        name: "VP B3",
        link: "VPB3",
        clickable: true,
        triggerOverlayName: "main_air",
        hasData: true,
      },
      {
        name: "TKO 137",
        link: "X",
        clickable: false,
        triggerOverlayName: "",
        hasData: null,
      },
      {
        name: "VP A1",
        link: "VPA1",
        clickable: true,
        triggerOverlayName: "main_air",
        hasData: true,
      },
      {
        name: "VP A2",
        link: "VPA2",
        clickable: true,
        triggerOverlayName: "main_air",
        hasData: true,
      },
      {
        name: "VP A3",
        link: "VPA3",
        clickable: true,
        triggerOverlayName: "main_air",
        hasData: true,
      },
      {
        name: "VP A4",
        link: "VPA4",
        clickable: true,
        triggerOverlayName: "Air_P03",
        hasData: true,
      },
      {
        name: "VP A5",
        link: "VPA5",
        clickable: true,
        triggerOverlayName: "main_air",
        hasData: true,
      },
      {
        name: "VP A6",
        link: "VPA6",
        clickable: true,
        triggerOverlayName: "main_air",
        hasData: true,
      },
      {
        name: "VP A7",
        link: "VPA7",
        clickable: true,
        triggerOverlayName: "main_air",
        hasData: true,
      },
    ],
  },
  {
    name: "Ecology",
    fullName: "Habitat Map",
    icon: "/icons/ecology.svg",
    list: [],
  },

  {
    name: "Water",
    fullName: "Water Quality",
    icon: "/icons/water.svg",
    list: [],
  },
  {
    name: "Visual",
    fullName: "Landscape & Visual",
    icon: "/icons/visual.svg",
    list: [
      { name: "1", link: "LVI1", clickable: true },
      { name: "2A", link: "LVI2A", clickable: true },
      { name: "2B", link: "LVI2B", clickable: true },
      { name: "3", link: "LVI3", clickable: true },
      { name: "4", link: "LVI4", clickable: true },
      { name: "5", link: "LVI5", clickable: true },
      { name: "6", link: "LVI6", clickable: true },
      { name: "7", link: "LVI7", clickable: true },
      { name: "8", link: "LVI8", clickable: true },
      { name: "9", link: "LVI9", clickable: true },
      { name: "10", link: "LVI10", clickable: true },
      { name: "11A", link: "LVI11A", clickable: true },
      { name: "11B", link: "LVI11B", clickable: true },
      { name: "12", link: "LVI12", clickable: true },
    ],
  },
  // {
  //   name: "Other Aspects",
  //   fullName: "Other Aspects",
  //   icon: "/icons/three-dots.svg",
  //   list: [],
  // },
];
