<template>
  <div class="canvas-container">
    <canvas ref="heroLightpass"></canvas>
    <div v-if="!firstFrameLoaded" class="spinner"></div>
    <div v-if="isShowingCompressed" class="loading-text">
      Loading high-res images...
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";

export default {
  name: "ScrollImageAnimation",
  setup() {
    const heroLightpass = ref(null);
    let context;
    const TOTAL_FRAMES = 300;
    const LAST_FRAME_INDEX = TOTAL_FRAMES - 1;
    let isScrolling;
    let targetFrameIndex = 0;
    let currentFrameIndex = 0;
    let animating = false;
    const fps = 24;
    const frameDuration = 1000 / fps;
    let lastFrameTime = 0;
    let preloadedImages = [];
    let loadedFrames = 0;
    const firstFrameLoaded = ref(false);
    const isShowingCompressed = ref(false);
    const compressedImages = [];

    const currentFrame = (index, isCompressed) =>
      isCompressed
        ? `./scroller-animation/tko_241212_compressed/${index.toString()}.webp`
        : `./scroller-animation/tko_241212_2/${index.toString()}.webp`;

    const preloadImages = async () => {
      // First load all compressed images
      const loadCompressedBatch = async () => {
        const batchSize = 10;
        for (let i = 0; i <= LAST_FRAME_INDEX; i += batchSize) {
          const batch = [];
          for (let j = 0; j < batchSize && i + j <= LAST_FRAME_INDEX; j++) {
            const index = i + j;
            batch.push(loadCompressedImage(index));
          }
          await Promise.all(batch);
        }
      };

      // Then load high-res images
      const loadHighResBatch = async () => {
        const batchSize = 5; // Smaller batch size for high-res
        for (let i = 0; i <= LAST_FRAME_INDEX; i += batchSize) {
          const batch = [];
          for (let j = 0; j < batchSize && i + j <= LAST_FRAME_INDEX; j++) {
            const index = i + j;
            batch.push(loadHighResImage(index));
          }
          await Promise.all(batch);
        }
      };

      // Split loading functions
      const loadCompressedImage = (index) => {
        return new Promise((resolve) => {
          const compressedImg = new Image();
          compressedImg.src = currentFrame(index, true);

          compressedImg.onload = () => {
            if (!firstFrameLoaded.value && index === 0) {
              updateImage(compressedImg);
              firstFrameLoaded.value = true;
            }
            compressedImages[index] = compressedImg;
            loadedFrames++;
            resolve();
          };

          // Add error handling for compressed images
          compressedImg.onerror = () => {
            console.warn(`Failed to load compressed image ${index}`);
            // Create a blank canvas as fallback
            const fallbackCanvas = document.createElement("canvas");
            fallbackCanvas.width = 1920;
            fallbackCanvas.height = 1080;
            const ctx = fallbackCanvas.getContext("2d");
            ctx.fillStyle = "#000000";
            ctx.fillRect(0, 0, fallbackCanvas.width, fallbackCanvas.height);

            // Convert canvas to image
            const fallbackImg = new Image();
            fallbackImg.src = fallbackCanvas.toDataURL();
            compressedImages[index] = fallbackImg;
            loadedFrames++;
            resolve();
          };
        });
      };

      const loadHighResImage = (index) => {
        return new Promise((resolve) => {
          const fullResImg = new Image();
          fullResImg.src = currentFrame(index, false);

          // Add timeout for high-res images
          const timeout = setTimeout(() => {
            console.warn(`Timeout loading high-res image ${index}`);
            preloadedImages[index] = compressedImages[index]; // Keep compressed version
            resolve();
          }, 10000); // 10 second timeout

          fullResImg.onload = () => {
            clearTimeout(timeout);
            preloadedImages[index] = fullResImg;
            if (Math.round(currentFrameIndex) === index) {
              updateImage(fullResImg);
            }
            resolve();
          };

          fullResImg.onerror = () => {
            clearTimeout(timeout);
            console.warn(`Failed to load high-res image ${index}`);
            preloadedImages[index] = compressedImages[index]; // Fallback to compressed
            resolve();
          };
        });
      };

      // Load compressed images first, then high-res
      await loadCompressedBatch();
      loadHighResBatch(); // Don't await this - let it load in background
    };

    const updateImage = (img) => {
      if (img && img.complete && context) {
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        // Scale the image to fit the canvas
        const scale = Math.max(
          context.canvas.width / img.width,
          context.canvas.height / img.height
        );
        const x = (context.canvas.width - img.width * scale) / 2;
        const y = (context.canvas.height - img.height * scale) / 2;
        context.drawImage(img, x, y, img.width * scale, img.height * scale);

        // Update compressed status
        isShowingCompressed.value = img.src.includes("compressed");
      }
    };

    const easeInOutSine = (t) => (1 + Math.sin(Math.PI * t - Math.PI / 2)) / 2;

    const animateToFrame = (timestamp) => {
      if (!animating) return;

      if (timestamp - lastFrameTime < frameDuration) {
        requestAnimationFrame(animateToFrame);
        return;
      }

      lastFrameTime = timestamp;

      const frameDifference = targetFrameIndex - currentFrameIndex;
      // Increase the animation speed for larger differences
      const speed = Math.min(1, Math.abs(frameDifference) / 10);
      
      if (Math.abs(frameDifference) < 0.01) {
        currentFrameIndex = targetFrameIndex; // Snap to target
        animating = false;
      } else {
        currentFrameIndex += frameDifference * Math.max(0.2, speed);
      }

      let roundedCurrentFrameIndex = Math.round(currentFrameIndex);

      // Modified this part to use compressed images if high-res isn't available
      if (roundedCurrentFrameIndex <= loadedFrames) {
        const imageToShow =
          preloadedImages[roundedCurrentFrameIndex] ||
          compressedImages[roundedCurrentFrameIndex];
        if (imageToShow) {
          updateImage(imageToShow);
        }
      }

      requestAnimationFrame(animateToFrame);
    };

    const handleScroll = () => {
      clearTimeout(isScrolling);
      const scrollTop = document.documentElement.scrollTop;
      const maxScrollTop = document.documentElement.scrollHeight - window.innerHeight;
      const scrollFraction = scrollTop / maxScrollTop;
      
      // Ensure scrollFraction is clamped between 0 and 1
      const clampedScrollFraction = Math.max(0, Math.min(1, scrollFraction));
      
      // Remove Math.ceil to allow for frame 0
      targetFrameIndex = Math.floor(
        Math.max(0, Math.min(LAST_FRAME_INDEX, 
        easeInOutSine(clampedScrollFraction) * LAST_FRAME_INDEX))
      );

      console.log({
        scrollTop,
        maxScrollTop,
        scrollFraction,
        clampedScrollFraction,
        targetFrameIndex
      });

      if (!animating) {
        animating = true;
        requestAnimationFrame(animateToFrame);
      }

      isScrolling = setTimeout(() => {
        animating = false;
      }, 200);
    };

    onMounted(() => {
      const canvas = heroLightpass.value;
      context = canvas.getContext("2d");
      canvas.width = 1920;
      canvas.height = 1080;
      preloadImages();
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return {
      heroLightpass,
      isShowingCompressed,
      firstFrameLoaded,
    };
  },
};
</script>

<style scoped>
html {
  height: 100%;
}

body {
  height: 1000%;
  background: #000;
}

canvas {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.canvas-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.spinner {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  z-index: 1;
}

.loading-text {
  position: fixed;
  left: 20px;
  bottom: 20px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 8px 12px;
  border-radius: 4px;
  z-index: 1;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>