//test
<template>
  <div class="side-panel col-2">
    <div class="position-absolute w-100 h-100">
      <!-- >>sub panel with icons -->
      <div
        id="sub-panel-container"
        class="w-100 h-100 d-flex flex-column justify-content-center"
      >
        <div class="sub-panel">
          <button
            v-for="category in categories"
            :key="category.fullName"
            @click="setActiveCategory(category.fullName)"
            :class="{ active: activeCategory === category.fullName }"
          >
            <img :src="category.icon" />
            <div>
              {{ category.name }}
            </div>
          </button>
        </div>
      </div>
      <div
        class="main-panel h-100 d-flex flex-column justify-content-start align-items-center"
      >
        <h2 id="categoryTitle">{{ activeCategory }}</h2>
        <!-- >>traffic type html -->
        <div v-if="sceneManager.activeCategory == 'Noise'">
          <div class="traffic-type-buttons">
            <button
              class="noiseTypeButton road-traffic"
              :class="{ selected: trafficType === 'road' }"
              @click="setTrafficType('road')"
            >
              Road Traffic
            </button>
            <button
              class="noiseTypeButton marine-traffic"
              :class="{ selected: trafficType === 'marine' }"
              @click="setTrafficType('marine')"
            >
              Marine Traffic
            </button>
          </div>
        </div>
        <!-- >>main panel of VP lists -->
        <ul class="list">
          <li
            v-for="item in activeMainList"
            :key="item"
            :class="{
              clickable: item.clickable && item.hasData === true,
              unclickable: !item.clickable,
              selected: isSelected(item),
              noData: item.hasData === false,
            }"
            @click="handleItemClick(item)"
          >
            {{ item.name }}
          </li>
        </ul>
        <img
          id="habitatLegend1"
          src="/webframe/map/image/habitatMapLegend2.png"
          alt=""
          srcset=""
          v-if="activeCategory === 'Habitat Map'"
        />

        <div id="bottomContainer" v-if="activeCategory === 'Water Quality'">
          <p id="instruction">Select a scenario</p>
          <button
            id="mitigatedBtn"
            class="button mitigated"
            :class="{ selected: sceneManager.WQMitigatedState }"
            @click="sceneManager.WQMitigatedState = true"
          >
            Mitigated
          </button>
          <button
            id="unmitigatedBtn"
            class="button unmitigated"
            :class="{ selected: !sceneManager.WQMitigatedState }"
            @click="sceneManager.WQMitigatedState = false"
          >
            Unmitigated
          </button>
          <hr />
          <div class="legend">
            <div id="legendTitle">Legend</div>
            <div class="legendItems">
              <span class="complied legendIcon"></span>
              <label>Complied with the criteria</label>
            </div>
            <div class="legendItems">
              <span class="exceeded legendIcon"></span>
              <label>
                Exceeded the criteria
                <template v-if="!sceneManager.WQMitigatedState">
                  <span style="font-size: 0.9em">
                    (Suspended solids and sedimentation rates during
                    construction phase)
                  </span>
                </template>
              </label>
            </div>
            <template v-if="!sceneManager.WQMitigatedState">
              <div class="WQMitigation-measures">
                <div class="WQMitigation-title">
                  Key mitigation measures considered for operational phase
                </div>
                <ul class="WQMitigation-list">
                  <li class="WQMitigation-item">
                    Precautionary design measures to prevent emergency
                    discharges during operation.
                  </li>
                  <li class="WQMitigation-item">
                    Emergency contingency plan to deal with power or treatment
                    failure.
                  </li>
                </ul>
              </div>
            </template>
            <div class="legendItems blue1">
              <span class="na legendIcon"></span>
              <label class="blue"
                >Not applicable due to provisioning of noise insulation with
                air-conditioning</label
              >
            </div>
          </div>
        </div>
        <div
          id="prevailing"
          v-if="
            sceneManager.activeCategory == 'Noise' ||
            sceneManager.activeCategory == 'Air' ||
            sceneManager.activeCategory == 'Landscape & Visual'
          "
        >
          <div
            @click="openModal(activeCategory)"
            style="
              font: 0.9vw sans-serif;
              /* text-decoration: underline; */
              cursor: pointer;
              display: flex;
              justify-content: center;
              text-align: center;
            "
          >
            <div
              style="text-decoration: underline"
              v-if="activeCategory == 'Noise' && trafficType == 'road'"
            >
              Prevailing Background Noise
            </div>
            <div
              style="text-decoration: underline"
              v-if="activeCategory == 'Noise' && trafficType == 'marine'"
            >
              Prevailing Marine Traffic Noise
            </div>

            <div
              style="text-decoration: underline"
              v-if="activeCategory == 'Air'"
            >
              Background Air Pollutants Concentration
            </div>
            <div v-if="activeCategory == 'Landscape & Visual'">
              <div style="text-decoration: underline; font-size: 16px">
                Landscape Plans under Mitigated Scenario
              </div>
              <div
                style="
                  text-decoration: none;
                  padding-left: 30px;
                  padding-right: 30px;
                  display: block;
                  margin-top: 5px;
                  text-align: left;
                  font-size: 14px;
                "
              >
                (Remark: Details refer to S.11 of the EIA Report)
              </div>
            </div>
          </div>
        </div>
        <div id="goToMap" v-if="!sceneManager.mapShow">
          <router-link to="/eia">
            <div @click="showMap">Back to map</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <span
        class="close"
        @click="closeModal"
        style="margin-left: auto; width: 40px; height: 40px; font-size: 40px"
        >&times;</span
      >
      <iframe
        :src="modalIframeSrc"
        style="margin-top: 20px"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { sceneManager } from "@/store/sceneManager";
import { categories } from "@/data/categories";

const activeCategory = ref("Viewpoints");

//>> check if the VP is selected
const isSelected = (item) => {
  return item.link === sceneManager.currentScene;
};

const showMap = () => {
  sceneManager.mapShow = true;
  sceneManager.currentScene = "Panoramas Background";
};

const activeMainList = computed(() => {
  const category = categories.find(
    (cat) => cat.fullName === activeCategory.value
  );

  //>> if traffic type is marine, return the marine list, otherwise return the noise list
  if (trafficType.value === "marine" && activeCategory.value === "Noise") {
    return category ? category.marine_list : [];
  } else {
    return category ? category.list : [];
  }
});

const handleItemClick = (item) => {
  if (
    (item.clickable && item.hasData === true) ||
    (activeCategory.value === "Viewpoints" && item.clickable) ||
    activeCategory.value === "Landscape & Visual"
  ) {
    if (activeCategory.value === "Landscape & Visual") {
      // Use sceneManager to store the selected LVI
      sceneManager.selectedLVI = item.name;
      sceneManager.mapShow = true;
    } else {
      sceneManager.currentScene = item.link;
      sceneManager.triggerOverlayName = item.triggerOverlayName;
      sceneManager.mapShow = false;
    }
  }
};

const setActiveCategory = (category) => {
  // Add check for Ecology category
  if (
    category === "Habitat Map" ||
    category === "Water Quality" ||
    category === "Landscape & Visual"
  ) {
    showMap();
  }
  if (category === "Other Aspects") {
    //pop up a modal that shows Other_Aspects_20241026.html
    modalIframeSrc.value = "/text/Other Aspects_20241218.html";
    showModal.value = true;
  } else {
    activeCategory.value = category;
    sceneManager.activeCategory = category;
    updateTriggerOverlayName();
  }
};

//>> update the trigger overlay name
const updateTriggerOverlayName = () => {
  const category = categories.find(
    (cat) => cat.fullName === activeCategory.value
  );
  if (category) {
    const list =
      activeCategory.value === "Noise" && trafficType.value === "marine"
        ? category.marine_list
        : category.list;

    const item = list.find((item) => item.link === sceneManager.currentScene);
    if (item && item.triggerOverlayName) {
      sceneManager.triggerOverlayName = item.triggerOverlayName;
    } else {
      // Set a default trigger overlay name if no specific one is found
      sceneManager.triggerOverlayName = "";
    }
  }
};

//>> modal pop up that shows the iframe
const showModal = ref(false);
const modalIframeSrc = ref("");

const openModal = (activeCategory) => {
  if (activeCategory == "Noise") {
    if (trafficType.value === "road") {
      modalIframeSrc.value = "/table/PrevailingBackgroundNoise.html";
    } else if (trafficType.value === "marine") {
      modalIframeSrc.value = "/table/PrevailingMarineTrafficNoise.html";
    }
  } else if (activeCategory == "Air") {
    modalIframeSrc.value =
      "/table/BackgroundAirPollutantsConcentration_241213.html";
  } else if (activeCategory == "Landscape & Visual") {
    modalIframeSrc.value =
      "/table/Pages 28 to 36 from CE40_EIA_S11_LVIA_20241216_figure.pdf";
  }

  showModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
};

const trafficType = ref("road");
const setTrafficType = (type) => {
  trafficType.value = type;
  updateTriggerOverlayName();
};
</script>

<style scoped>
* {
  text-decoration: none;
  /* box-sizing: border-box; */
}

.side-panel {
  position: absolute;
  top: 12vh;
  height: 88vh;
  right: 0;
  display: flex;
  font-family: Arial, sans-serif;
}

.main-panel {
  padding: 20px;
  background-color: #f4f4f4;
  overflow-y: auto;
  overflow-x: hidden;
}

.main-panel h2 {
  font: 25px arial, sans-serif;
  color: #008768;
  padding: 1.6vh;
  text-align: center;
  margin-bottom: 0;
}

.main-panel ul {
  list-style-type: none;
  /* padding: 20px; */
  width: 100%;
}

.main-panel ul li {
  padding: 0.3vw;
  font: 2.5vh sans-serif;
  cursor: pointer;
  width: auto;
  text-align: center;
}

.main-panel ul li.unclickable {
  /* background-color: rgb(214, 214, 214); */
  cursor: default;
  font-weight: bold;
}

.main-panel ul li.noData {
  cursor: default;
  color: rgb(188, 188, 188);
}

.main-panel ul li.clickable:not(.selected):hover {
  background-color: rgb(214, 214, 214);
}

#sub-panel-container {
  pointer-events: none;
  position: absolute;
  left: -80px;
  height: 88vh;
}
.sub-panel {
  width: 80px;
  pointer-events: auto;
  /* top: 80px; */
  background-color: #f0f0f0ea;
  display: flex;
  flex-direction: column;
  /* rounded corder */
  border-radius: 8px 0px 0px 8px;
  overflow: hidden;
}

.sub-panel button {
  width: auto;
  height: 80px;
  border: none;
  background: none;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  font: 12px sans-serif;
  border-bottom: 1.2px solid #aaaaaa;
}

.sub-panel button.active {
  background-color: #0186692f;
}

.sub-panel img {
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
}

#categoryTitle {
  font-size: 1.7vw !important;
}

ul.list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}

ul.list li {
  margin-bottom: 5px;
}

#goToMap {
  position: sticky;
  margin-top: auto;
  bottom: 0;
  width: 100%;
  padding: 0.5vh 0px 0px 0px;
  text-decoration: none !important;
}

#goToMap div {
  /* rounded corner green button */
  /* background-color: #008768; */
  /* add border */
  border: 2px solid #008768;
  color: #008768;
  width: auto;
  height: auto;
  margin: auto;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 8px 8px 8px 8px;
  font: 1rem sans-serif;
  z-index: 100;
  /* remove link style */
  text-decoration: none !important;
  background-color: #fefefe;
}

#habitatLegend1 {
  width: 100%;
  height: auto;
  margin-top: 5vh;
}

/* below for WQ */

#bottomContainer {
  width: 100%;
  margin-top: 30px;
  /* margin-bottom: auto; */
}
#instruction {
  width: 100%;
  text-align: center;
  font-size: 1.2vw;
}

.button {
  display: block;
  width: 80%;
  margin: 0.5vw auto;
  padding: 0.8vw;
  text-align: center;
  font-size: 1vw;
  font-weight: bold;
  cursor: pointer;
  color: rgb(0, 0, 0);
  border: #004d40 solid 3px;
  background: none;
}
.selected {
  background-color: #004d40; /* Dark green */
  border: #004d40 solid 3px;
  color: white !important;
}

hr {
  width: 100%;
  margin-top: 1vw;
  margin-bottom: 1vw;
  height: 1px;
  background-color: #535353cb;
  border: none;
}
.legend {
  text-align: left;
  font-size: 1vw;

  /* width: 100%; */
}

#legendTitle {
  font-weight: 600;
  margin-bottom: 1vw !important;
}

.legendItems {
  display: flex;
  align-items: top;
  margin-top: 1vw;
  width: 100%;
  /* margin-bottom: 2.7vw; */
  /* width: 90vw; */
}
.legendItems .blue {
  font-size: 16px;
  padding-left: 1vw;
  width: 70%;
}

.legendItems label {
  flex-grow: 1;
  padding-left: 1vw;
}

.legendIcon {
  margin-top: 0.3vw;
  width: 12px !important;
  height: 12px !important;
  display: inline-block;
  flex-shrink: 0;
}
.complied {
  background-color: #8af678; /* light green */
}
.exceeded {
  background-color: #ff0000; /* light red */
}

.na {
  background-color: #0070c0; /* light red */
}

.blue1 {
  display: none;
}

/* modal */

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fefefe;
  padding: 20px 20px 20px 20px;
  border: 1px solid #888;
  width: 90%;
  height: 90%;
  position: relative;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.traffic-type-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.noiseTypeButton {
  flex: 1;
  margin: 0 0.2rem;
  padding: 0.1rem;
  font-size: 0.9rem;
  border: 2px solid #008768;
  background-color: #ffffff;
  color: #008768;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
}

.noiseTypeButton.selected {
  background-color: #008768;
  color: #ffffff;
}

.main-panel ul li.selected.noData {
  background-color: #a0a0a0; /* Example color */
  color: #ffffff; /* Example text color */
  cursor: default;
}

.noiseTypeButton:first-child {
  margin-left: 0;
}

.noiseTypeButton:last-child {
  margin-right: 0;
}

.main-panel ul li.selected {
  background-color: #008768;
  border: none;
  /* color: white; */
}

/* Add these CSS rules */
.WQMitigation-measures {
  padding-left: 5px;
  background-color: #f8f8f8;
  margin: 15px 0;
  border-left: 4px solid #004d40;
  border-radius: 4px;
}

.WQMitigation-title {
  font-weight: bold;
  margin-bottom: 12px;
  color: #004d40;
  font-size: 1vw !important;
}

.WQMitigation-list {
  list-style: none;
  padding-left: 0;
}

li.WQMitigation-item {
  position: relative;
  padding-left: 8px !important;
  margin-bottom: 8px;
  line-height: 1.4;
  text-align: left !important;
  font-size: 1vw !important;
}

.WQMitigation-item::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #004d40;
}
</style>