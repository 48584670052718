<template>
  <aside class="side-panel" :class="{ show: panelType === 'projectInfo' }">
    <div id="info-container" ref="infoContainer" class="row">
      <div
        id="sideButton-Container"
        ref="sideButtonContainer"
        :class="{ show: panelType === 'projectInfo' }"
      >
        <div id="side-buttons" class="">
          <a class="sideButton" href="#scroll-target-1">Background</a>
          <a class="sideButton" href="#scroll-target-2">Description</a>
          <a class="sideButton" href="#scroll-target-3">Benefits</a>
        </div>
      </div>
      <div
        id="infoContainer"
        data-bs-spy="scroll"
        data-bs-target="#side-buttons"
        data-bs-smooth-scroll="false"
        data-v-offset="500"
        tabindex="0"
      >
        <div class="section" id="Background">
          <div id="scroll-target-1" class="scroll-target"></div>
          <h2 class="section-title">Background</h2>
          <div class="content">
            <div v-html="backgroundContent"></div>
          </div>
        </div>
        <div class="section" id="Description">
          <div id="scroll-target-2" class="scroll-target"></div>
          <h2 class="section-title">Description</h2>
          <div class="content">
            <div v-html="descriptionContent"></div>
          </div>
        </div>
        <div class="section" id="Benefits">
          <div id="scroll-target-3" class="scroll-target"></div>
          <h2 class="section-title">Benefits of the Project</h2>
          <div class="content">
            <div v-html="benefitsContent"></div>
          </div>
        </div>
      </div>
      <div id="goToMap">
        <router-link to="/eia">
          <div>Explorer Map</div>
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script>
import { computed, ref, onMounted, onUnmounted } from "vue";

const backgroundContent = ref("");
const descriptionContent = ref("");
const benefitsContent = ref("");

// Fetch the content of background.html
fetch("/text/background.html")
  .then((response) => response.text())
  .then((html) => {
    backgroundContent.value = html;
  })
  .catch((error) => {
    console.error("Error loading background content:", error);
    backgroundContent.value = "Failed to load background content.";
  });

// Fetch the content of description.html
fetch("/text/description.html")
  .then((response) => response.text())
  .then((html) => {
    descriptionContent.value = html;
  })
  .catch((error) => {
    console.error("Error loading description content:", error);
    descriptionContent.value = "Failed to load description content.";
  });

// Fetch the content of benefits.html
fetch("/text/benefits.html")
  .then((response) => response.text())
  .then((html) => {
    benefitsContent.value = html;
  })
  .catch((error) => {
    console.error("Error loading benefits content:", error);
    benefitsContent.value = "Failed to load benefits content.";
  });

export default {
  name: "SidePanelInfo",
  props: {
    panelType: String,
  },
  setup(props) {
    const infoContainer = ref(null);
    const sideButtonContainer = ref(null);
    const adjustHeight = () => {
      if (infoContainer.value && sideButtonContainer.value) {
        sideButtonContainer.value.style.height = `${infoContainer.value.clientHeight}px`;
        sideButtonContainer.value.style.left = `calc(-${sideButtonContainer.value.clientWidth}px + 3px)`;
      }
    };
    const panelTitle = computed(() => {
      if (props.panelType === "projectInfo") {
        return "Project Info";
      } else if (props.panelType === "environmentalImpacts") {
        return "Environmental Impacts";
      }
      return "";
    });

    onMounted(() => {
      adjustHeight();
      window.addEventListener("resize", adjustHeight);
      // Initialize ScrollSpy
      const scrollSpyElement = document.querySelector("#infoContainer");
      if (scrollSpyElement) {
        new bootstrap.ScrollSpy(scrollSpyElement, {
          target: "#side-buttons",
          offset: 500,
        });
      }
    });
    onUnmounted(() => {
      window.removeEventListener("resize", adjustHeight);
      // Consider cleaning up ScrollSpy if necessary
    });

    return {
      panelTitle,
      infoContainer,
      sideButtonContainer,
      backgroundContent,
      descriptionContent,
      benefitsContent,
    };
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box !important;
}
.side-panel {
  display: flex !important;
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 0vw;
  width: auto;
  /* height: 100vh; */
  transition: transform 0.3s ease-in-out;
  background-color: none;
  transform: translateX(100%); /* Initially off-screen to the right */
  /* align-content: center; */
  justify-content: right;
}

.side-panel.show {
  /* display: block; */
  transform: translateX(0); /* Slide in to its natural position */
}

#info-container {
  box-sizing: border-box !important;
  padding: 400px 2vw 2vw 4vw;
  width: 30vw;
  height: auto !important;
  /* overflow: scroll; */
  background-color: rgb(255, 255, 255);
}

#sideButton-Container {
  transform: translateX(100vw); /* Initially off-screen to the right */
  position: fixed !important;
  left: 0; /* Adjusted to align with the container */
  top: 0vh;
  height: 100% !important;

  width: auto; /* Set a fixed width for the side buttons */
  background-color: rgba(148, 148, 148, 0.192);
  transition: transform 0.5s ease-in-out;
  z-index: -100;
  padding: 0px;
}

#sideButton-Container.show {
  transform: translateX(0); /* Slide in to its natural position */
}

#side-buttons {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 12vh;
  padding-right: 15px;
  background-color: rgba(255, 255, 255, 0);
  font: 2vmin sans-serif;
  color: #dfdfdf;
}

.sideButton {
  color: #ffffff;
  padding: 15px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  width: auto;
  text-shadow: 0px 0px 2px #000000;
}

.sideButton:hover {
  background-color: rgba(255, 255, 255, 0.253);
}

.sideButton.active {
  background-color: #a8c7c7e7;
  color: #000000;
  border-left: 3px solid #008768;
  text-shadow: none;
}

.section-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2vw;
  padding-top: 24h;
}

.content {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2vw;
  padding-top: 2vh;
  line-height: 1.5;
}

.section {
  padding-top: 4vh;
  /* position: relative; */
}

a {
  text-decoration: none !important;
}

.scroll-target {
  position: relative;
  top: -12vh;
  /* background-color: rgba(188, 143, 143, 0.251); */
  /* height: 1vh;
  width: 3vw; */
}

#goToMap {
  position: sticky;
  margin-top: auto;
  bottom: 0;
  width: 100%;
  padding: 40px 40px 20px 40px;
  /* gradient background color */
  background: linear-gradient(
    0deg,
    rgb(255, 255, 255) 60%,
    rgba(255, 255, 255, 0) 100%
  );
}

#goToMap div {
  /* rounded corner green button */
  background-color: #008768;
  color: white;
  width: auto;
  height: auto;
  margin: auto;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 80px 80px 80px 80px;
  font: 1rem sans-serif;
  z-index: 100;
}
</style>
