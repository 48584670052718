<template>
  <div class="home-page" @scroll="handleScroll">
    <div id="title" :style="{ opacity: titleOpacity }">
      <div id="container">
        <h1>
          DEVELOPMENT OF TSEUNG KWAN O AREA 137<br />
          AND ASSOCIATED RECLAMATION SITES
        </h1>
        <p>Investigation, Design and Construction</p>
        <img src="/icons/scroll_down.svg" alt="" srcset="" />
      </div>
    </div>

    <!-- Add more content to make the page scrollable -->
    <!-- <ScrollAnimation id="scrollAnimation" /> -->
    <ScrollAnimation id="scrollAnimation" />
    <SidePanelInfo :panel-type="panelType" />

    <div id="disclaimerModal" class="modal">
      <div class="modal-content">
        <span class="modal-title">Disclaimer</span>
        <span class="close">&times;</span>
        <div id="disclaimerText"></div>
      </div>
    </div>
    <div class="mobile-disclaimer" id="mobileDisclaimer">
      <div class="disclaimer-content">
        Please use a desktop browser for the optimal experience
        <span class="mobile-close" @click="closeMobileDisclaimer">&times;</span>
      </div>
    </div>
  </div>
</template>

<script>
// import ScrollAnimation from "../components/ScrollerAnimation.vue";
import SidePanelInfo from "../components/SidePanelInfo.vue";
// import ScrollAnimation from "../components/ScrollerAnimation2.vue";
import ScrollAnimation from "../components/ScrollerAnimation4.vue";

export default {
  name: "HomePage",
  components: {
    ScrollAnimation,
    SidePanelInfo,
    ScrollAnimation,
  },
  props: {
    panelType: String,
  },
  data() {
    return {
      titleOpacity: 1, // New data property for dynamic opacity
      hasSeenDisclaimer: false,
    };
  },
  methods: {
    handleScroll() {
      this.scrolledPast300 = window.scrollY > 300;
      let newOpacity = 1 - scrollY / 300;
      newOpacity = newOpacity < 0 ? 0 : newOpacity; // Ensure opacity is not less than 0
      this.titleOpacity = newOpacity;
    },
    checkAndLoadDisclaimer() {
      const hasSeenDisclaimer = sessionStorage.getItem("hasSeenDisclaimer");
      if (!hasSeenDisclaimer) {
        this.loadDisclaimer();
      }
    },
    loadDisclaimer() {
      fetch("/text/disclaimer.html")
        .then((response) => response.text())
        .then((html) => {
          document.getElementById("disclaimerText").innerHTML = html;
          document.getElementById("disclaimerModal").style.display = "block";

          const closeBtn = document.querySelector(".close");
          closeBtn.onclick = () => {
            document.getElementById("disclaimerModal").style.display = "none";
            sessionStorage.setItem("hasSeenDisclaimer", "true");
          };
        })
        .catch((error) => console.error("Error loading disclaimer:", error));
    },
    closeMobileDisclaimer() {
      document.getElementById('mobileDisclaimer').style.display = 'none';
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.checkAndLoadDisclaimer();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.home-page {
  padding-top: 12vh; /* Adjust based on your HeaderBar height */
  min-height: 200vh; /* Make the page scrollable */
  /* background-color: #be4848; */
}

#scrollAnimation {
  z-index: -11;
}

#title {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
}

#container {
  display: flex;
  flex-direction: column;

  padding: 12vh 2vw 2vw 4vw;
  width: auto;
  height: auto !important;
  /* background-color: rgb(0, 0, 0); */
  color: white;
  /* text shadow */
  text-shadow: 0px 2px 8px #000000;
}

img {
  padding-top: 50px;
  align-self: center !important;
  width: 8vw;
  /* height: 2vh; */
}

#title h1 {
  font-size: 2.5vw;
  font-weight: bold;
  margin: 0;
}

#title p {
  padding-top: 24px;
  font-weight: normal;
  margin: 0;
  font: 1.8vw arial, sans-serif;
  font-style: thin;
}
.fade-out {
  opacity: 0;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.modal-title {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center; /* Center only the title */
  color: #008768;
  font-family: Arial, Helvetica, sans-serif;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  color: #aaa;
  font-size: 32px;
  font-weight: bold;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.mobile-disclaimer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  font-family: Arial, Helvetica, sans-serif;
  /* Prevent scrolling of the background while disclaimer is shown */
  overflow: hidden;
}

.disclaimer-content {
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  background-color: #00353e;
  padding: 30px;
  border-radius: 8px;
  color: white;
  text-align: center;
  font-size: 16px;
  max-width: 80vw;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  /* Ensure it stays above other content */
  z-index: 1001;
}

.mobile-close {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  text-align: center;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-close:hover {
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .mobile-disclaimer {
    display: block;
  }
}
</style>