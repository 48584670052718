<template>
  <header class="header-bar">
    <span id="mainTitle">Development of Tseung Kwan O Area 137 and Associated Reclamation Sites  ‎</span><span id="subTitle">- Investigation, Design and Construction</span>
  </header>
</template>

<script>
export default {
  name: 'HeaderBar',
}
</script>

<style scoped>
.header-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 6vh;
  background-color: #00353E;
  color: white;
  padding-left: 20px;
  z-index: 100;
  font-family: Arial, Helvetica, sans-serif;
  vertical-align: middle;
  display: flex;
  align-items: center;
  font-size: 2.2vh;
}

#mainTitle {
  font-size: 2.2vh;
  /* font-weight: bold; */
}
#subTitle {
  /* font-weight: normal; */
  font-size: 2vh;
}
</style>