<template>
  <nav class="nav-bar" :class="{ show: show }">
    <router-link to="/" @click="scrollToTop" :class="{ active: isHomePage }">
      <img src="/icons/home.svg" alt="Home" /> Home
    </router-link>
    <router-link to="/project-info" :class="{ active: isProjectInfoActive }">
      <img src="/icons/info.svg" alt="Project Info" /> Project Info
    </router-link>
    <router-link to="/eia" :class="{ active: isEIAPage }">
      <img src="/icons/data.svg" alt="Environmental Impacts" /> Environmental
      Impacts
    </router-link>
    <router-link to="/3d" :class="{ active: is3DPage }">
      <img src="/icons/3d.svg" alt="3D Navigation" /> 3D Navigation
    </router-link>
  </nav>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "NavBar",
  props: {
    show: Boolean,
    scrollPosition: Number,
  },
  setup() {
    const route = useRoute();
    const isHomePage = computed(() => route.name === "Home");
    const isProjectInfoActive = computed(() => route.name === "ProjectInfo");
    const isEIAPage = computed(() => route.name === "EIA");
    const is3DPage = computed(() => route.name === "Model");

    const scrollToTop = () => {
      window.scrollTo({ top: 0 });
    };

    return {
      isHomePage,
      isProjectInfoActive,
      isEIAPage,
      is3DPage,
      scrollToTop,
    };
  },
};
</script>

<style scoped>
.nav-bar {
  position: fixed;
  top: -60px;
  left: 0;
  right: 0;
  background-color: #f0f0f0;
  /* padding: 1rem; */
  display: flex;
  justify-content: flex-end;
  transition: top 0.3s ease-in-out;
  z-index: 99;
  height: 6vh;
  padding-right: 1vw;
}

.nav-bar.show {
  top: 6vh; /* Adjust based on your HeaderBar height */
}

.nav-bar a {
  display: flex;
  align-items: center;
  justify-content: center; /* Optional: centers the content horizontally */
  text-decoration: none;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2vh;
  padding: 0 1vw;
}

.nav-bar a:hover {
  background-color: #DFECE9;
}

.nav-bar a.active {
  background-color: #008768;
  color: white;
}

.nav-bar img {
  margin-right: 5px;
  width: 2vh;
  height: 2vh;
}

.nav-bar a.active img {
  filter: brightness(0) invert(1);
}

</style>