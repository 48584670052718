<template>
  <iframe
    id="tour"
    ref="tourFrame"
    @load="onIframeLoad"
    :src="sceneManager.iframeSrc"
  >
  </iframe>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { sceneManager } from "@/store/sceneManager";

defineProps({
  msg: String,
  mediaIndex: Number,
});

const tourFrame = ref(null);
// const rp = ref(null);
const isIframeLoaded = ref(false);

function onIframeLoad() {
  if (tourFrame.value) {
    let iframe = tourFrame.value;
    let iframeWindow = iframe.contentWindow;
    sceneManager.iframeWindow = iframeWindow;
  }
}

window.addEventListener("message", (event) => {
  if (event.data === "tourLoaded") {
    // rp.value = iframeWindow.tour._getRootPlayer();
    // console.log(rp.value.mainPlayList.get("selectedIndex"));
  }
  if (event.data.toString().startsWith("VP")) {
    console.log("VP received: ", event.data);
    sceneManager.setCurrentScene(event.data);
  }
});
</script>

<style scoped>
iframe {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
}

button {
  /* button in the middle of screen */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}
</style>
