<template>
  <iframe @load="onMapLoad" id="map" src="./webframe/map/index.html"> </iframe>
</template>

<script setup>
import { sceneManager } from "@/store/sceneManager";
import { defineProps, watch, ref, onMounted, onUnmounted } from "vue";

defineProps({
  msg: String,
});

window.addEventListener("message", (event) => {
  if (event.data.toString().startsWith("trigger_")) {
    //extract the text after trigger_
    let triggerVP = event.data.split("_")[1];
    sceneManager.setCurrentScene(triggerVP);
    sceneManager.mapShow = false;
  }
});

const hideIframe = () => {
  // let iframe = document.querySelector("#map");
  // iframe.classList.add("slide-out");
};
watch(
  () => sceneManager.activeCategory,
  (newVal) => {
    const iframe = document.getElementById("map");
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        { type: "updateCategory", category: newVal },
        "*"
      );
    }
  }
);

watch(
  () => sceneManager.WQMitigatedState,
  (newVal) => {
    const iframe = document.getElementById("map");
    if (!newVal) {
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage("WQUnmitigated", "*");
      }
    } else {
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage("WQMitigated", "*");
      }
    }
  }
);
watch(
  () => sceneManager.selectedLVI,
  (newVal) => {
    if (newVal) {
      console.log("LVI new val: " + newVal);
      const iframe = document.getElementById("map");
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          { type: "zoomToLVI", name: newVal },
          "*"
        );
      } else {
        console.log("iframe not found");
      }
      sceneManager.selectedLVI = null; // Reset after sending
    }
  }
);
const isMapLoaded = ref(false);
const onMapLoad = (event) => {
  if (event.data === "mapLoaded") {
    console.log("Map fully loaded");
    isMapLoaded.value = true;
    const iframe = document.getElementById("map");
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        { type: "updateCategory", category: sceneManager.activeCategory },
        "*"
      );
    }
  }
};

onMounted(() => {
  window.addEventListener("message", onMapLoad);
});

onUnmounted(() => {
  window.removeEventListener("message", onMapLoad);
});
</script>

<style scoped>
iframe {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
}
</style>
