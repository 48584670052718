<template>
  <div class="model-page" style="width: 100%; height: 100%">
    <iframe id="model" @load="onModelLoad" src="./model/index.html"> </iframe>

    <div id="instructionModal" class="modal">
      <div class="modal-content">
        <span class="modal-title">Instructions</span>
        <span class="close">&times;</span>
        <img
          src="/img/instruction.jpg"
          alt="Instructions"
          class="instruction-image"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAttrs, ref, onMounted } from "vue";
import { sceneManager } from "@/store/sceneManager";

defineProps({
  msg: String,
  panelType: String,
});

function onModelLoad() {}

onMounted(() => {
  checkAndShowInstructions();
});

function checkAndShowInstructions() {
  // const hasSeenInstructions = sessionStorage.getItem("hasSeenInstructions");
  // if (!hasSeenInstructions) {
  //   showInstructions();
  // }
  //show instructions anyway
  showInstructions();
}

function showInstructions() {
  const modal = document.getElementById("instructionModal");
  modal.style.display = "block";

  const closeBtn = document.querySelector(".close");
  closeBtn.onclick = () => {
    modal.style.display = "none";
    // sessionStorage.setItem("hasSeenInstructions", "true");
  };
}
</script>

<style scoped>
iframe {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.modal-title {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
  color: #008768;
  font-family: Arial, Helvetica, sans-serif;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  color: #aaa;
  font-size: 32px;
  font-weight: bold;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.instruction-image {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
</style>
