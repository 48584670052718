// FILE: router.js
import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './views/HomePage.vue'
import EIAPage from './views/EIAPage.vue'
import ModelPage from './views/ModelPage.vue'

const routes = [
  { path: '/', name: 'Home', component: HomePage,alias: '/'  },
  { path: '/project-info', name: 'ProjectInfo', component: HomePage, alias: '/xxx' }, // Add this line
  { path: '/eia', name: 'EIA', component: EIAPage },
  { path: '/3d', name: 'Model', component: ModelPage },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router